import { Map } from './map/Map'
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBasemaps } from '../../../state/features/basemapSlice';
import { useAppSelector } from '../../../state/hooks';
import { Tile } from './map/tile-layer/Tile';
import { LayerRestApi } from '../../../util/restapi/layer';
import { getServices } from '../../../state/features/serviceSlice';
import { WmsLayer } from './map/wms-layer/WmsLayer';
import { Draw } from './map/draw/Draw';
import { BasemapRestApi } from '../../../util/restapi/basemap';
import WmsInfo from './map/wms-info/WmsInfo';
import { Tile as OlTile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { setFeatureExtent, setSelectedFeature } from '../../../state/features/selectedFeatureSlice';
import { changeInfoVisibility, setSelectedFeatureForInfo, setSelectedFeatureForInfoEdit, setSelectedFeatureId, setUnit2Names } from '../../../state/features/infoSlice';
import { UnitsRestApi } from '../../../util/restapi/units';
import { Digitization } from './map/digitization/Digitization';
import { ChangeExtent } from './map/ChangeExtent';
import { DigiDrawForEdit } from './map/digitization/digi-draw/DigiDrawForEdit';
import SelectedFeatureExtent from './map/select-feature/SelectedFeatureExtent';
import { isSelectedFeatureOnTable } from '../../../state/features/tableSlice';
import PanoLook from './map/pano-look/PanoLook';
import { DrawCities } from './map/draw-cities/DrawCities';
import WfsLayer from './map/wfs-layer/WfsLayer';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { setToastMessageStatus } from '../../../state/features/digitizationSlice';
import { ButtonState } from '../../shared/button-state/ButtonState';
import { RootState } from '../../../state/store';
import Overlay from './map/overlay/Overlay';

export const MapContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const toast = useRef<any>();
    const centerX = 3395271.8857;
    const centerY = 4835689.4652;
    const zoom = 16.5;
    const extent = [2817774.6107, 4265797.6745, 5102324.5121, 5278435.4253]
    const basemaps = useAppSelector(state => state.basemap.value);
    const customMap = useAppSelector(state => state.customMap.customMap);
    const services = useAppSelector(state => state.service.services);
    const changeExtent = useAppSelector((state) => state.changeExtent.changeExtent)
    const selectedFeatureOnTable = useAppSelector((state) => state.table.isSelectedFeatureOnTable)
    const extentVisibility = useAppSelector((state) => state.selectedFeature.extent)
    const panoramaVisibility = useAppSelector((state) => state.panorama.visible)
    const showToastMessage = useAppSelector((state) => state.digitization.showToastMessage)
    const token = useAppSelector((state) => state.login.token);
    const buttonVisibility = useAppSelector((state) => state.buttonState.value);
    const openDigitization = useAppSelector((state) => state.digitization.openDigitization)
    const openEditGeometry = useAppSelector((state) => state.digitization.openGeometryEdit)
    const [wmsItems, setWmsItems] = useState<any>([]);
    const language = useAppSelector((state: RootState) => state.language.value);
    const cm = useRef(null);
    // const [selectedCoordinate, setSelectedCoordinate] = useState<number[] | null>(null);
    // const [isSelectedFeature, setIsSelectedFeature] = useState(false)

    async function onWmsSelected(arr: { url: string, wms: OlTile<TileWMS> }[], coordinate: number[]) {

        let featureItems: any[] = [];
        setWmsItems([]);
        if (arr.length === 0) {
            // setSelectedCoordinate(null);
            return;
        }
        const result = await LayerRestApi.getGeojsonFromGetFeatureInfoUrlArray(arr.map(a => a.url));
        // setSelectedCoordinate(coordinate);
        const wmsFeatures = result
            .filter(r => r.length > 0)
            .map((features, i) => {
                const wms = arr[i].wms
                const service = services.find(ss => ss.id === wms.get('serviceId'))!
                return { service, features }
            });

        if (wmsFeatures && wmsFeatures.length === 1) {
            const featureId = wmsFeatures[0].features[0].id.split('.')[1];
            const featureProperties = wmsFeatures[0].features[0].properties;

            dispatch(setSelectedFeature(wmsFeatures[0].features[0]))
            dispatch(setFeatureExtent(true))
            // setIsSelectedFeature(true);
            getUnits(+featureId)
            dispatch(changeInfoVisibility(true));
            dispatch(isSelectedFeatureOnTable(false))
            dispatch(setSelectedFeatureForInfo(featureProperties));
            dispatch(setSelectedFeatureForInfoEdit(featureProperties));
            dispatch(setSelectedFeatureId(featureId));
        }

        else if (wmsFeatures && wmsFeatures.length > 1) {
            for (const feature of wmsFeatures) {
                const serviceItem = featureItems.find(obj => obj.label === feature.service.alias);
                if (!serviceItem) {
                    featureItems.push({
                        label: feature.service.alias,
                        items: [{
                            id: feature.features[0].id,
                            key: feature.features[0].id,
                            icon: "pi pi-angle-right",
                            seperator: true,
                            label: feature.features[0].properties['adi' + (language === 'tr' ? '' : ('_' + language))],
                            command: () => {
                                dispatch(setSelectedFeature(feature.features[0]))
                                dispatch(setFeatureExtent(true))
                                // setIsSelectedFeature(true);
                                getUnits(+feature.features[0].id.split('.')[1])
                                dispatch(changeInfoVisibility(true));
                                dispatch(isSelectedFeatureOnTable(false))
                                dispatch(setSelectedFeatureForInfo(feature.features[0].properties));
                                dispatch(setSelectedFeatureForInfoEdit(feature.features[0].properties));
                                dispatch(setSelectedFeatureId(feature.features[0].id.split('.')[1]));
                                setWmsItems([]);
                            },
                        }]
                    })
                } else {
                    serviceItem.items.push({
                        id: feature.features[0].id,
                        key: feature.features[0].id,
                        icon: "pi pi-angle-right",
                        seperator: true,
                        label: feature.features[0].properties['adi' + (language === 'tr' ? '' : ('_' + language))],
                        command: () => {
                            dispatch(setSelectedFeature(feature.features[0]))
                            dispatch(setFeatureExtent(true))
                            // setIsSelectedFeature(true);
                            getUnits(+feature.features[0].id.split('.')[1])
                            dispatch(changeInfoVisibility(true));
                            dispatch(isSelectedFeatureOnTable(false))
                            dispatch(setSelectedFeatureForInfo(feature.features[0].properties));
                            dispatch(setSelectedFeatureForInfoEdit(feature.features[0].properties));
                            dispatch(setSelectedFeatureId(feature.features[0].id.split('.')[1]));
                            setWmsItems([]);
                        },
                    })
                }
            }
            setWmsItems(featureItems)
        }

    }

    const getUnits = (featureId: number) => {
        let subUnitNames: any[] = []
        UnitsRestApi.getUnits().then((res: any) => {
            res.data.response.find((build: any) => {
                if (build.build_id === featureId) {
                    subUnitNames.push(build)
                }
            })
            dispatch(setUnit2Names(subUnitNames));
        })
    }

    useEffect(() => {
        BasemapRestApi.getBasemaps().then((res: any) => {
            res.data.publicBasemaps.find((basemap: any) => {
                if (basemap.priority === 1) {
                    basemap.visible = true;
                }
            })
            dispatch(getBasemaps(res.data.publicBasemaps));
        })

        try {
            LayerRestApi.getServices().then((response) => {
                const success = response.data.success;
                if (success) {
                    const services = response.data.services.services;
                    //ilk başta yapılar ve aöf buroları acık gelsin
                    const immovables = services.find((layer: any) => { return layer.name === 'tasinmazlar' })
                    if (immovables) {
                        immovables.layers.find((obj: any) => {
                            if (obj.name === 'yapilar_usr') {
                                obj.visible = true;
                            }
                            if (obj.name === 'aof_alan_usr') {
                                obj.visible = true;
                            }
                        })
                    }
                    dispatch(getServices(services));
                }
            });
        } catch (error) {
            return;
        }
    }, [token])

    useEffect(() => {
        if (showToastMessage) {
            toast.current?.show({ severity: 'success', detail: (t('DIGI.Transaction completed successfully')), life: 3000 });
            dispatch(setToastMessageStatus(false))
        }
    }, [showToastMessage])

    useEffect(() => {
    }, [openEditGeometry, openDigitization])


    return (

        <Map centerX={centerX} centerY={centerY} zoom={zoom} extent={extent}>
            {basemaps.length > 0 && basemaps.map((basemap: any) => basemap.visible &&
                <Tile
                    layername={basemap.layername}
                    key={basemap.url} url={basemap.url}
                    visible={basemap.visible}
                />
            )}
            {customMap.length > 0 && customMap.map((customMap: any) =>
                <Tile
                    layername={customMap.name}
                    key={customMap.id}
                    url='https://kampus360.anadolu.edu.tr/rest/v1/geoserver/anadolu/wms/'
                    visible={customMap.visible}
                    opacity={customMap.opacity} />
            )
            }
            {panoramaVisibility &&
                <WmsLayer
                    serviceId={2}
                    tag='GEOSERVER_PANOGPS'
                    url='https://kampus360.anadolu.edu.tr/rest/v1/geoserver/anadolu/wms/'
                    layername='panogps' />
            }
            {services.map(ss => {
                return <WmsLayer
                    key={ss.id}
                    serviceId={ss.id}
                    tag={ss.type}
                    url='https://kampus360.anadolu.edu.tr/rest/v1/geoserver/anadolu/wms/'
                    layername={ss.layers.filter(l => l.visible).map(l => l.name)}
                >
                </WmsLayer>
            })}
            <WmsInfo callback={onWmsSelected} tag='GEOSERVER_WRITE' />
            <Draw />
            <DrawCities />
            <Digitization />
            <Overlay wmsItems={wmsItems}></Overlay>
            {extentVisibility && <SelectedFeatureExtent />}
            {changeExtent && <ChangeExtent />}
            {selectedFeatureOnTable && <DigiDrawForEdit />}
            {panoramaVisibility && <PanoLook />}
            <Toast ref={toast} position='bottom-center' className='login-toast' style={{ zIndex: '9 !important' }} />
            {(buttonVisibility === ButtonState.MEASUREMENT || openDigitization || openEditGeometry) && token && services.map(ss => {
                if (ss.type === "GEOSERVER_WRITE") {
                    return ss.layers.map(layer => {
                        return <WfsLayer
                            key={layer.id}
                            layername={layer.name}
                            visible={layer.visible}
                        >
                        </WfsLayer>
                    })
                }
            })}
        </Map>
    )
}
