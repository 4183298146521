import './Share.scss';
import { useEffect, useRef, useState } from 'react'
import { Popup } from '../shared/popup/Popup'
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import { useCurrentWidth } from '../shared/resize/Resize';
import { ButtonState } from '../shared/button-state/ButtonState';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../state/hooks';
interface IProps {
    onClose: () => void,
}

export const Share = (props: IProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef<any>();
    const width = useCurrentWidth();
    const shareUrl = window.location.href;
    const [mobile, setMobile] = useState(false);
    const buttonVisibility = useAppSelector(state => state.buttonState.value);

    useEffect(() => {
        if (width <= 767) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [width])

    useEffect(() => {
    }, [mobile])

    useEffect(() => {
    }, [buttonVisibility])


    /**
     * Bu fonksiyon ile popup'ın kapanmasını sağlıyoruz.
     */
    const closeSharebox = () => {
        props.onClose();
    }

    /**
     * Bu fonksiyon ile bağlantıyı kopluyoruz.
     */
    const copyLink = () => {
        try {
            const text = document.createElement('textarea');
            text.innerText = shareUrl;
            document.body.appendChild(text);
            text.select();
            document.execCommand('copy');
            document.body.removeChild(text);
            toast.current.show({ severity: 'success', detail: (t('SHARE.Success Message')), life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'error', detail: (t('SHARE.Error Message')), life: 3000 });
            console.warn(error);
        }
    }

    const shareboxContainer =
        <>
            <div className='sharebox-header'>
                {t('SHARE.Share')}
            </div>
            <div className='sharebox-container'>
                <div className='sharebox-input' onClick={copyLink}>
                    <div className='copy-link-input'>{t('SHARE.Copy Link')}
                        <i className='pi pi-link' />
                    </div>
                </div>

                <div className='social-media-icons'>
                    <div className='icon'>
                        <a href={`https://api.whatsapp.com/send?text=` + shareUrl}
                            data-action='share/whatsapp/share' target='blank'>
                            <i className='pi pi-whatsapp' /></a>
                    </div>
                    <div className='icon'>
                        <a href='https://twitter.com/share?ref_src=twsrc%5Etfw' data-show-count='true'
                            target='blank'>
                            <i className='pi pi-twitter' /></a>

                    </div>
                    <div className='icon'>
                        <a target='blank'
                            href='https://www.facebook.com/sharer/sharer.php?u=https://kampus360.anadolu.edu.tr%2F%23%2Fapp&amp;src=sdkpreparse'>
                            <i className='pi pi-facebook' /></a>
                    </div>
                </div>
                <Toast ref={toast} position='bottom-center' />
            </div>
        </>

    return (
        <>
            {!mobile && buttonVisibility === ButtonState.SHARE && <Popup
                className='sharebox-container'
                visible={buttonVisibility === ButtonState.SHARE}
                onHide={() => closeSharebox()}
                content={shareboxContainer}>
            </Popup>
            }

            {mobile && buttonVisibility === ButtonState.MOBILE_SHARE && <div className='mobile-sharebox-container'>
                <div className='mobile-share'>
                    <div className='mobile-header'>
                        <i className='pi pi-arrow-left' onClick={() => dispatch(setButtonState(ButtonState.HAMBURGER))} />
                        <span>{t('MOBILE.Share')}</span>
                    </div>
                    <div className='mobile-container'>
                        <div className='sharebox-input' onClick={copyLink}>
                            <div className='copy-link-input'>{t('SHARE.Copy Link')}
                                <i className='pi pi-link' />
                            </div>
                        </div>

                        <div className='social-media-icons'>
                            <div className='icon'>
                                <a href={`https://api.whatsapp.com/send?text=` + shareUrl}
                                    data-action='share/whatsapp/share' target='blank'>
                                    <i className='pi pi-whatsapp' /></a>
                            </div>
                            <div className='icon'>
                                <a href='https://twitter.com/share?ref_src=twsrc%5Etfw' data-show-count='true'
                                    target='blank'>
                                    <i className='pi pi-twitter' /></a>

                            </div>
                            <div className='icon'>
                                <a target='blank'
                                    href='https://www.facebook.com/sharer/sharer.php?u=https://kampus360.anadolu.edu.tr%2F%23%2Fapp&amp;src=sdkpreparse'>
                                    <i className='pi pi-facebook' /></a>
                            </div>
                        </div>
                        <Toast ref={toast} position='bottom-center' />
                    </div>

                </div>
            </div>
            }
        </>
    )
}

